import { always } from "kremling";
import { Anchor } from "../Styleguide/Anchor";
import { ButtonKind } from "../Styleguide/Button";
import { Icon, IconVariant } from "../Styleguide/Icon";
import { useConsoleParams } from "../Utils/paramHelpers";

export function FreeTrialBanner(props: FreeTrialBannerProps) {
  const { customerOrgId } = useConsoleParams();
  const customerOrgSlug = customerOrgId ? `/${customerOrgId}` : ``;

  const expired = props.daysLeft === 0;

  return (
    <section
      className={always(
        "h-[44px] w-full flex justify-between mt-[64px] items-center pl-[30px] pr-[120px] text-consoleBase font-light pr-narrowConsole lg:pr-wideConsole"
      ).toggle("text-white bg-brick", "text-darkNavy bg-peach", expired)}
    >
      {expired ? (
        <div>Free trial has ended</div>
      ) : (
        <div>Free trial—{props.daysLeft} days remaining.</div>
      )}
      <div>
        <Anchor
          to={`/console${customerOrgSlug}/org-settings/subscription`}
          kind={ButtonKind.custom}
          className="flex items-center"
        >
          <span className="underline">View subscription</span>
          <Icon variant={IconVariant.rightArrow} className="ml-[8px]" />
        </Anchor>
      </div>
    </section>
  );
}

interface FreeTrialBannerProps {
  daysLeft: number;
}
