import { always } from "kremling";
import { ButtonHTMLAttributes, forwardRef } from "react";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      kind,
      className,
      buttonSize = ButtonSize.large,
      children,
      notchType = ButtonNotchType.none,
      disabled = false,
      notchBackground,
      ...otherProps
    } = props;

    return (
      <button
        className={buttonClasses({
          kind,
          buttonSize,
          className,
          disabled,
          hasNotches: notchType !== ButtonNotchType.none,
        })}
        {...otherProps}
        ref={ref}
        disabled={disabled}
      >
        {children}
        {ButtonNotches({ notchType, kind, notchBackground })}
      </button>
    );
  }
);

export function ButtonNotches(props: ButtonNotchesProps) {
  if (
    [ButtonKind.classic, ButtonKind.transparent, ButtonKind.icon].includes(
      props.kind
    ) ||
    props.notchType === ButtonNotchType.none
  ) {
    return null;
  }

  const notchHeight = 3;
  const notchWidth = props.notchType === ButtonNotchType.large ? 60 : 30;
  const triangleWidth = 4;
  const notchBackground = props.notchBackground ?? "white";

  return (
    <>
      <svg
        width={notchWidth}
        height={notchHeight}
        className="absolute"
        style={{ top: 0 }}
      >
        <rect
          x={triangleWidth}
          width={notchWidth - 2 * triangleWidth}
          height={notchHeight}
          fill={notchBackground}
          stroke={notchBackground}
        />
        <polygon
          points={`0,0 ${triangleWidth},0 ${triangleWidth},${notchHeight}`}
          fill={notchBackground}
          stroke={notchBackground}
        />
        <polygon
          points={`${notchWidth},0 ${notchWidth - triangleWidth},0 ${
            notchWidth - triangleWidth
          },${notchHeight}`}
          fill={notchBackground}
          stroke={notchBackground}
        />
      </svg>
      <svg
        width={notchWidth}
        height={notchHeight}
        className="absolute"
        style={{ bottom: 0 }}
      >
        <rect
          x={triangleWidth}
          width={notchWidth - 2 * triangleWidth}
          height={notchHeight}
          fill={notchBackground}
          stroke={notchBackground}
        />
        <polygon
          points={`0,${notchHeight} ${triangleWidth},${notchHeight} ${triangleWidth},0`}
          fill={notchBackground}
          stroke={notchBackground}
        />
        <polygon
          points={`${notchWidth},${notchHeight} ${
            notchWidth - triangleWidth
          },${notchHeight} ${notchWidth - triangleWidth},0`}
          fill={notchBackground}
          stroke={notchBackground}
        />
      </svg>
    </>
  );
}

export interface ButtonNotchesProps {
  kind: ButtonKind;
  notchType: ButtonNotchType;
  notchBackground?: string;
}

export enum ButtonSize {
  medium = "md",
  large = "lg",
  small = "sm",
}

export enum ButtonNotchType {
  normal,
  small,
  large,
  none,
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind: ButtonKind;
  buttonSize?: ButtonSize;
  notchType?: ButtonNotchType;
  notchBackground?: string;
}

export enum ButtonKind {
  // Product
  primary = "primary",
  secondary = "secondary",
  transparent = "transparent",
  classic = "classic",
  classicPeach = "classicPeach",
  icon = "icon",

  // Marketing
  marketingPrimary = "marketingPrimary",
  marketingSecondary = "marketingSecondary",
  marketingTertiary = "marketingTertiary",

  // Custom
  custom = "custom",
}

interface ButtonClassesArg {
  kind: ButtonKind;
  buttonSize: ButtonSize;
  className?: string;
  disabled: boolean;
  hasNotches: boolean;
}

export function buttonClasses({
  kind,
  buttonSize,
  className = "",
  disabled,
  hasNotches,
}: ButtonClassesArg) {
  const kindClasses = buttonKindClasses[kind] || "";
  const isStyled = styledButtonKinds.includes(kind);

  return always(kindClasses)
    .always(className)
    .always("disabled:cursor-not-allowed")
    .maybe(styledButtonClasses, isStyled)
    .maybe("py-2.5 px-5", isStyled && buttonSize === ButtonSize.large)
    .maybe("py-2 px-2 text-sm", isStyled && buttonSize === ButtonSize.medium)
    .maybe("py-.5 px-1 text-xs", isStyled && buttonSize === ButtonSize.small)
    .maybe("text-sm", !isStyled && buttonSize === ButtonSize.medium)
    .maybe("text-xs", !isStyled && buttonSize === ButtonSize.small)
    .maybe("bg-gray-300 cursor-not-allowed", disabled)
    .maybe("p-2", kind === ButtonKind.icon && buttonSize !== ButtonSize.small)
    .maybe("p-1", kind === ButtonKind.icon && buttonSize === ButtonSize.small)
    .maybe("relative", hasNotches)
    .toString();
}

const styledButtonClasses = `inline-flex items-center justify-center cursor-pointer`;

const styledButtonKinds = [
  ButtonKind.primary,
  ButtonKind.secondary,
  ButtonKind.marketingPrimary,
  ButtonKind.marketingSecondary,
  ButtonKind.marketingTertiary,
];

const buttonKindClasses = {
  [ButtonKind.primary]:
    "bg-darkNavy text-white disabled:bg-darkNavy-800 disabled:opacity-80 rounded-[3px] hover:text-white hover:border-peach hover:bg-peach font-light",
  [ButtonKind.secondary]:
    "text-darkNavy border-primary-600 border hover:bg-peach hover:text-white hover:border-peach rounded-[3px] font-light",
  [ButtonKind.transparent]: "no-underline hover:no-underline",
  [ButtonKind.classic]:
    "text-royalBlue underline hover:text-primary hover:underline",
  [ButtonKind.classicPeach]:
    "text-peach no-underline hover:text-peach hover:underline",
  [ButtonKind.icon]:
    "text-royalBlue hover:bg-gray-200 inline-flex justify-items-center align-items-center rounded",
  [ButtonKind.marketingPrimary]:
    "rounded-[3px] bg-darkNavy text-white py-2.5 px-[25px]",
  [ButtonKind.marketingSecondary]:
    "rounded-[3px] py-2.5 px-[25px] border border-darkNavy text-darkNavy font-light",
  [ButtonKind.marketingTertiary]: "bg-peach text-darkNavy bg-marketingTertiary",
};
