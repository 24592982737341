import { ButtonKind } from "./Button";
import { Anchor } from "./Anchor";
import { Icon, IconVariant } from "./Icon";
import { Menu, MenuButton, MenuList } from "@reach/menu-button";
import { always } from "kremling";
import { useConsoleParams } from "../Utils/paramHelpers";
import { useLocation } from "react-router";
import { useAssetUrls } from "../Utils/assetUrls";

export function ConsolePrimaryNav(props: Props) {
  const { customerOrgId } = useConsoleParams();
  const location = useLocation();
  const [logoUrl] = useAssetUrls("/static/baseplate-logo-white.png");

  return (
    <nav
      className={`fixed top-0 left-0 h-[64px] w-screen text-white`}
      style={{
        background:
          "transparent radial-gradient(closest-side at 0% 0%, #1A2E61CC 0%, #1A2E61 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <div className="flex justify-between items-center h-full">
        <section className="h-full flex items-center justify-between">
          <Anchor
            kind={ButtonKind.transparent}
            to="/"
            className="w-[128px] ml-[30px]"
          >
            <img alt="baseplate logo" src={logoUrl} />
          </Anchor>
        </section>
        <section className="lg:mr-4 mr-14 flex justify-end items-center font-extralight mr-narrowConsole lg:mr-wideConsole">
          <Anchor
            kind={ButtonKind.transparent}
            to="mailto:support@baseplate.cloud"
            className="text-[19px]"
          >
            Contact Support
          </Anchor>
          {location.pathname.startsWith("/docs") ? (
            <Anchor
              kind={ButtonKind.transparent}
              to="/console"
              className="text-[19px] ml-[50px]"
            >
              Console
            </Anchor>
          ) : (
            <Anchor
              kind={ButtonKind.transparent}
              to="/docs"
              className="text-[19px] ml-[50px]"
            >
              Documentation
            </Anchor>
          )}
          <Menu>
            {({ isExpanded }) => (
              <>
                <MenuButton
                  className={always(
                    "hover:bg-darkNavy-800 p-1 lg:p-2 rounded ml-[50px] flex items-center"
                  ).maybe("bg-darkNavy-800 rounded-b-none", isExpanded)}
                >
                  <Icon
                    className="text-white"
                    size={22}
                    variant={IconVariant.user}
                    alt="user profile icon"
                    fill="white"
                  />
                  <span className="text-[14px] ml-[5px]">▾</span>
                </MenuButton>
                <MenuList className="bg-[#efefef] text-primary-600 w-[160px] border-b border-primary-600 consoleProfile font-light">
                  <div className="pr-[10px]">
                    <Anchor
                      kind={ButtonKind.custom}
                      to={`/console/${customerOrgId}/profile`}
                      className="pt-[16px] block text-right text-[19px] leading-[26px] hover:underline"
                    >
                      User Profile
                    </Anchor>
                    <Anchor
                      kind={ButtonKind.custom}
                      href="/logout"
                      className="pt-[20px] block text-right text-[19px] leading-[26px] hover:underline mb-[14px]"
                    >
                      Log out
                    </Anchor>
                  </div>
                </MenuList>
              </>
            )}
          </Menu>
        </section>
      </div>
    </nav>
  );
}

interface Props {}
