import { createContext, HTMLProps } from "react";
import { always } from "kremling";

export function Fieldset(props: FieldsetProps) {
  const { className, children, ...otherProps } = props;

  return (
    <FieldsetContext.Provider
      value={{
        isFieldset: true,
      }}
    >
      <fieldset
        className={always(className as string).always("flex items-center")}
        {...otherProps}
      >
        {children}
      </fieldset>
    </FieldsetContext.Provider>
  );
}

export interface FieldsetContextData {
  isFieldset: boolean;
}

export const FieldsetContext = createContext<FieldsetContextData>({
  isFieldset: false,
});

export interface FieldsetProps extends HTMLProps<HTMLFieldSetElement> {}
